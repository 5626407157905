import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const AboutImages = ({ src, className = "", style }) => {
  const data = useStaticQuery(graphql`
    query {
      about1: file(relativePath: { eq: "about/about-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      about2: file(relativePath: { eq: "about/about-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      about3: file(relativePath: { eq: "about/about-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cb: file(relativePath: { eq: "about/cb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kt: file(relativePath: { eq: "about/kt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      af: file(relativePath: { eq: "about/af.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
  }
  return (
    <GatsbyImage
      fluid={data[src].childImageSharp.fluid}
      className={`img--${src} ${className}`}
      style={style}
    />
  )
}
export default AboutImages
