import React, { useState } from "react"

import Img from "../assets/images/about-images"
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion"
import { defaultTransition } from "../utils/easings"
import RightArrowSVG from "../assets/svg/right-arrow.svg"
import RightArrowThinSVG from "../assets/svg/right-arrow-thin.svg"

import ProjectAnimations from "../components/projects/projectAnimations"

// import useScreenHeightBody from "../hooks/useScreenHeightBody"
import { defaultTransition as transition } from "../utils/easings"

import "./about.us.scss"
import { useEffect } from "react"
import { useWindowSize } from "react-use"
import SEO from "../components/seo"
import scrollTo from "../animations/scrollTo"

// const SLIDE_VARIANTS = {
//   initial: {
//     // height: "100%",
//     opacity: 1,
//     zIndex: 10,
//     transition: { ...transition, duration: 0.4 },
//   },
//   active: {
//     // height: "100%",
//     opacity: 1,
//     zIndex: 11,
//     transition: { ...transition, duration: 0.4 },
//   },
//   exit: {
//     // height: "0%",
//     opacity: 0,
//     zIndex: 12,
//     transition: { ...transition, duration: 0.4 },
//   },
// }
const SLIDE_VARIANTS = {
  initial: {
    height: "100%",
    zIndex: 10,
    transition: { ...defaultTransition, duration: 1.6 },
  },
  active: {
    height: "100%",
    zIndex: 11,
    transition: { ...defaultTransition, duration: 1.6 },
  },
  exit: {
    height: "0%",
    zIndex: 12,
    transition: { ...defaultTransition, duration: 1.6 },
  },
}

const amtOfSections = 4

const people = [
  {
    name: "Craig Brown",
    image: "cb",
    description:
      "Friday’s founder and creative director has been developing strategies, leading creative studios and rolling out compelling brands for more than 20 years. He trained at the University of Canterbury's School of Fine Arts, gaining experience and building expertise with agencies in New Zealand and London before setting up Friday with his creative partner Katy. Craig also consults on brand strategy for larger organisations and government entities.",
  },
  {
    name: "Katy Clook",
    image: "kt",

    description:
      "Katy is an award-winning creative, conceptualist and copywriter. A co-owner of Friday and a published author, Katy has worked in traditional advertising and the creative industry for more years than she’s willing to admit. Her strategic thinking, clever ideas and skilled use of language shape content and campaigns, perception and brands. The stuff she says and does drives results. ",
  },
  // {
  //   name: "Alice Ferner",
  //   image: "af",

  //   description:
  //     "A talented designer, creative and illustrator, Alice has worked with agencies and retail corporates in both brand and marketing. She has a degree with First Class Honours in Visual Communications and the ability to turn a shady brief into an inspired solution. Her unique skill for creating concepts and bringing ideas to life across both traditional and digital platforms raises Friday’s level of innovation and expertise by several notches.",
  // },
  // {
  //   name: "Matt",
  //   image: "homepage7",

  //   description:
  //     "Beatae ad odio accusamus soluta delectus? Deserunt animi Unde sapiente incidunt cupiditate dolorum asperiores in expedita sit vero, non quae necessitatibus aperiam?",
  // },
]

const spring = {
  type: "spring",
  stiffness: 250,
  damping: 30,
}

const Person = ({ person, isActive, onClick }) => {
  return (
    <button onClick={onClick}>
      <motion.h3
        animate
        className={`person-button flex items-center justify-between ${
          isActive ? "active" : "inactive"
        }`}
        style={{ width: "7em" }}
      >
        {person.name}

        {isActive && (
          <motion.div
            layoutId="arrow"
            initial={false}
            transition={spring}
            style={{ width: "0.5em" }}
          >
            <RightArrowSVG />
          </motion.div>
        )}
      </motion.h3>
    </button>
  )
}

const AboutPeopleSection = ({ activePerson, setActivePerson }) => {
  const { width } = useWindowSize()
  if (width > 768) {
    return (
      <div className="page-wrapper">
        <div className="panel panel--text bg-white">
          <div className="flex flex-col space-y-6 flex-start">
            <AnimateSharedLayout>
              {people.map(person => (
                <Person
                  key={person.name}
                  person={person}
                  isActive={activePerson === person}
                  onClick={() => setActivePerson(person)}
                />
              ))}
            </AnimateSharedLayout>
          </div>

          <AnimatePresence exitBeforeEnter>
            <motion.p
              key={activePerson.name}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={transition}
              // animate={{ opacity: 1, transition: { delay: 0.4 } }}
              exit={{ opacity: 0, transition: { duration: 0.4 } }}
            >
              {activePerson.description}
            </motion.p>
          </AnimatePresence>
        </div>
        <div className="panel panel--image relative">
          {people.map(person => (
            <div className="z-0 absolute inset-0" key={person.name}>
              <Img src={person.image} />
            </div>
          ))}
          <AnimatePresence>
            <motion.div
              key={activePerson.image}
              className="h-screen absolute inset-0 overflow-hidden"
              variants={SLIDE_VARIANTS}
              initial={"initial"}
              animate={"active"}
              exit={"exit"}
            >
              <Img src={activePerson.image} className="h-screen" />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        {people.map(person => (
          <div key={person.name} className="person">
            <div className="panel--text">
              <h3>{person.name}</h3>
              <p>{person.description}</p>
            </div>
            <div className="panel--image">
              <Img src={person.image} />
            </div>
          </div>
        ))}
      </div>
    )
  }
}

const scrollDelay = 1400

const AboutUsPage = () => {
  const [activePerson, setActivePerson] = useState(people[0])

  ProjectAnimations()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="about-page">
        <SEO
          title="About us"
          description="Powerful strategies and big ideas. Meaningful brand identities
                and engaging, compelling websites and campaigns. We craft and
                create, positioning your brand, shaping the narrative, and
                giving you the means to talk to your audiences."
        />
        <section
          className="section-1 pin-fully-scrolled"
          style={{ marginTop: 0 }}
        >
          <div className="page-wrapper">
            <div className="panel panel--text bg-white">
              <h1>
                A compact and perfectly formed team of creative specialists
              </h1>
              <p className="mt-16 pr-16">
                Powerful strategies and big ideas. Meaningful brand identities
                and engaging, compelling websites and campaigns. We craft and
                create, positioning your brand, shaping the narrative, and
                giving you the means to talk to your audiences. Positively, and
                always with authenticity, we help you influence the way they
                think, feel and react.
              </p>
              <div className="scroll-to-items default-p">
                <div className="item" onClick={() => scrollTo("#what-we-do")}>
                  What we do{" "}
                  <RightArrowThinSVG
                    className="inline-block"
                    style={{ width: "0.38em" }}
                  />
                </div>
                <div
                  className="item"
                  onClick={() => scrollTo("#sectors-we-work-with")}
                >
                  Sectors we work with{" "}
                  <RightArrowThinSVG
                    className="inline-block"
                    style={{ width: "0.38em" }}
                  />
                </div>
                <div className="item" onClick={() => scrollTo("#our-team")}>
                  Company directors{" "}
                  <RightArrowThinSVG
                    className="inline-block"
                    style={{ width: "0.38em" }}
                  />
                </div>
              </div>
            </div>
            <div className="panel panel--image">
              <Img src="about1" className="h-full" />
            </div>
          </div>
        </section>
        <section className="section-2 pin-fully-scrolled" id="what-we-do">
          <div className="page-wrapper">
            <div className="panel panel--text bg-white">
              <h1>What we do and do well</h1>
              <p className="mt-16 pr-16">
                Online &amp; Offline <br />
                Brand Strategy &amp; Workshops
                <br />
                Naming
                <br />
                Brand Identity
                <br />
                Graphic Design
                <br />
                Digital UI &amp; UX Design &amp; Development
                <br />
                Concepts &amp; Creative Campaigns
                <br />
                Creative &amp; Art Direction
                <br />
                Digital Marketing
                <br />
                SEO
                <br />
                Copywriting &amp; Content Creation
                <br />
                Tone of Voice
                <br />
                Publication Design &amp; Content
                <br />
                Packaging Design
                <br />
                Photography & Videography Illustration <br />
                Animation <br />
              </p>
            </div>
            <div className="panel panel--image ">
              <Img src="about2" className="h-full" />
            </div>
          </div>
        </section>
        <section
          className="section-3 pin-fully-scrolled"
          id="sectors-we-work-with"
        >
          <div className="page-wrapper">
            <div className="panel panel--text bg-white">
              <h1>Sectors we work with</h1>
              <p className="mt-16 pr-16">
                Professional Services <br />
                Council &amp; Government <br />
                IT &amp; Technology <br />
                Construction &amp; Property Development <br />
                Arts <br />
                Retail &amp; FMCG <br />
                Education <br />
                Agriculture <br />
                Hospitality <br />
                Healthcare <br />
                Wellbeing <br />
                Events <br />
                Fashion <br />
              </p>
            </div>
            <div className="panel panel--image">
              <Img src="about3" className="h-full" />
            </div>
          </div>
        </section>
        <section className="section-4 pin-fully-scrolled" id="our-team">
          <AboutPeopleSection
            activePerson={activePerson}
            setActivePerson={setActivePerson}
          />
        </section>
      </div>
      {/* <motion.button
        // animate={{ opacity: sectionIndex < amtOfSections - 1 ? 1 : 0 }}
        className="next-slide-button about"
        // onClick={() => scrollPage(1)}
      >
        <h3>
          <DownArrowSVG
            className="inline fill-current"
            style={{
              width: "0.6em",
            }}
          />
        </h3>
      </motion.button> */}
    </>
  )
}

export default AboutUsPage
